// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../URLs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Level from "../../tree/Level.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Answer from "../../tree/Answer.js";
import * as Feather from "../../common/views/Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as TreeView from "../../tree/views/TreeView.js";
import * as R4SFilter from "./R4SFilter.js";
import * as PageAppView from "../../common/views/PageAppView.js";
import * as R4SChecklist from "../R4SChecklist.js";
import * as ButtonSlimIcon from "../../common/components/ButtonSlimIcon.js";
import * as ButtonSlimLink from "../../common/components/ButtonSlimLink.js";
import * as R4SChecklistId from "../R4SChecklistId.js";
import * as R4SQuestionsView from "./R4SQuestionsView.js";
import * as ButtonFilterToggle from "../../common/components/ButtonFilterToggle.js";

function filterFromChecklist(checklist) {
  var filters = Mithril$1.route.param("detType");
  var filters$1 = Mithril$1.route.param("answered");
  return [
          {
            name: "Type/Risk",
            query: "detType",
            value: filters !== undefined ? filters.split(",") : [],
            exclusive: false,
            availableValues: R4SChecklist.getDetentionTypes(checklist)
          },
          {
            name: "Status",
            query: "answered",
            value: filters$1 !== undefined ? filters$1.split(",") : [],
            exclusive: true,
            availableValues: [
              "Answered",
              "Unanswered"
            ]
          }
        ];
}

function toggleContent(vnode, id) {
  var currState = vnode.state.data;
  if (typeof currState !== "object") {
    return ;
  } else {
    return Mithril.setState(vnode, {
                TAG: "Loaded",
                checklist: currState.checklist,
                levels: currState.levels,
                newAnswers: currState.newAnswers,
                guidanceUrl: currState.guidanceUrl,
                filters: currState.filters,
                showModal: currState.showModal,
                activeItems: Prelude.$$Array.includes(currState.activeItems, id) ? Curry._2(Prelude.$$Array.keep, currState.activeItems, (function (i) {
                          return i !== id;
                        })) : Curry._2(Prelude.$$Array.concat, currState.activeItems, [id])
              });
  }
}

function createFilterHeader(vnode) {
  var match = vnode.state.data;
  if (typeof match !== "object") {
    return Mithril.empty;
  }
  var filters = match.filters;
  var guidanceUrl = match.guidanceUrl;
  var hasGuidance = Curry._1(Prelude.OptionExported.$$Option.isSome, guidanceUrl);
  var filterButton = React.jsx(ButtonSlimIcon.make, {
        icon: "filter",
        onClick: (function (param) {
            var curState = vnode.state.data;
            if (typeof curState !== "object") {
              return ;
            } else {
              return Mithril.setState(vnode, {
                          TAG: "Loaded",
                          checklist: curState.checklist,
                          levels: curState.levels,
                          newAnswers: curState.newAnswers,
                          guidanceUrl: curState.guidanceUrl,
                          filters: curState.filters,
                          showModal: true,
                          activeItems: curState.activeItems
                        });
            }
          }),
        className: "tw-pl-2"
      });
  var activeFilterButtons = filters.map(function (filter) {
        return filter.value.map(function (value) {
                    if (value !== "") {
                      return React.jsx(ButtonFilterToggle.make, {
                                  text: value,
                                  onClick: (function (param) {
                                      R4SFilter.toggleFilter(filters, filter.query, value);
                                    })
                                });
                    } else {
                      return Mithril.empty;
                    }
                  });
      });
  var guidanceLink = guidanceUrl !== undefined ? React.jsxs(ButtonSlimLink.make, {
          children: [
            React.jsx(Feather.make, {
                  icon: "book_open"
                }),
            ReactDOM.jsx("span", {
                  children: "Guidance"
                })
          ],
          href: guidanceUrl
        }) : Mithril.empty;
  if (hasGuidance) {
    return ReactDOM.jsxsKeyed("div", {
                children: [
                  ReactDOM.jsxs("div", {
                        children: [
                          filterButton,
                          guidanceLink
                        ],
                        className: "tw-flex tw-flex-row tw-items-center tw-justify-between"
                      }),
                  ReactDOM.jsx("div", {
                        children: activeFilterButtons,
                        className: Mithril.classList([
                              "tw-flex tw-flex-row tw-items-center tw-justify-start tw-flex-wrap",
                              "tw-gap-1 tw-px-1 tw-pt-2"
                            ], undefined)
                      })
                ],
                className: "tw-flex tw-flex-col tw-pt-2"
              }, Prelude.default(JSON.stringify(R4SFilter.toUrlSearch(filters)), ""), undefined);
  } else {
    return ReactDOM.jsxsKeyed("div", {
                children: [
                  filterButton,
                  activeFilterButtons
                ],
                className: Mithril.classList([
                      "tw-flex tw-flex-row tw-items-center tw-justify-start tw-flex-wrap",
                      "tw-gap-1 tw-pt-2"
                    ], undefined)
              }, Prelude.default(JSON.stringify(R4SFilter.toUrlSearch(filters)), ""), undefined);
  }
}

function init(vnode) {
  var checklistId = R4SChecklistId.Route.param("checklistId");
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  Prelude.thenDo(Query.read((newrecord.r4schecklists = {
              TAG: "Get",
              _0: checklistId
            }, newrecord)), (function (param) {
          var checklist = Prelude.$$Array.firstUnsafe(param.r4schecklists);
          var newrecord = Caml_obj.obj_dup(Query.makeRead());
          Prelude.thenDo(Query.read((newrecord.newAnswers = Answer.insideLevel(checklist.level_path), newrecord.levels = {
                      TAG: "Or",
                      _0: Level.childrenOf(checklist.level_id),
                      _1: {
                        TAG: "Get",
                        _0: checklist.level_id
                      }
                    }, newrecord)), (function (param) {
                  var levels = param.levels;
                  var level = Prelude.$$Array.firstUnsafe(Curry._2(Prelude.$$Array.keep, levels, (function (l) {
                              return Caml_obj.equal(l.id, checklist.level_id);
                            })));
                  var guidanceUrl = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, level.guidance_document_id, (function (docId) {
                              return URLs.markdownDocument(docId);
                            })), undefined);
                  Mithril.setState(vnode, {
                        TAG: "Loaded",
                        checklist: checklist,
                        levels: levels,
                        newAnswers: param.newAnswers,
                        guidanceUrl: guidanceUrl,
                        filters: filterFromChecklist(checklist),
                        showModal: false,
                        activeItems: []
                      });
                }));
        }));
}

async function update(vnode) {
  var checklistId = R4SChecklistId.Route.param("checklistId");
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  var match = await Query.read((newrecord.r4schecklists = {
          TAG: "Get",
          _0: checklistId
        }, newrecord));
  var checklist = Prelude.$$Array.firstUnsafe(match.r4schecklists);
  var newrecord$1 = Caml_obj.obj_dup(Query.makeRead());
  var match$1 = await Query.read((newrecord$1.newAnswers = Answer.insideLevel(checklist.level_path), newrecord$1));
  var currState = vnode.state.data;
  if (typeof currState !== "object") {
    return ;
  }
  var newAnswers = currState.newAnswers;
  var filters = currState.filters;
  var currentfilters = filterFromChecklist(checklist);
  if (match$1.newAnswers.length !== newAnswers.length || Caml_obj.notequal(R4SFilter.toUrlSearch(currentfilters), R4SFilter.toUrlSearch(filters))) {
    return Mithril.setState(vnode, {
                TAG: "Loaded",
                checklist: currState.checklist,
                levels: currState.levels,
                newAnswers: newAnswers,
                guidanceUrl: currState.guidanceUrl,
                filters: currentfilters,
                showModal: currState.showModal,
                activeItems: currState.activeItems
              });
  }
  
}

function make() {
  return Mithril.view(Mithril.onupdateAsync(Mithril.oninit(Mithril.component("NotReady"), init), update), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return React.jsxKeyed(PageAppView.make, {
                              children: Mithril.empty,
                              headerContent: {
                                NAME: "Title",
                                VAL: "Port checklists"
                              }
                            }, "", undefined);
                }
                var activeItems = match.activeItems;
                var filters = match.filters;
                var newAnswers = match.newAnswers;
                var levels = match.levels;
                var checklist = match.checklist;
                return React.jsxKeyed(PageAppView.make, {
                            children: Curry._2(Prelude.$$Array.concat, [
                                  createFilterHeader(vnode),
                                  React.jsxKeyed(R4SFilter.make, {
                                        showModal: match.showModal,
                                        filters: filters,
                                        onClear: (function (_event) {
                                            var curState = vnode.state.data;
                                            if (typeof curState !== "object") {
                                              return ;
                                            } else {
                                              return Mithril.setState(vnode, {
                                                          TAG: "Loaded",
                                                          checklist: curState.checklist,
                                                          levels: curState.levels,
                                                          newAnswers: curState.newAnswers,
                                                          guidanceUrl: curState.guidanceUrl,
                                                          filters: filters,
                                                          showModal: curState.showModal,
                                                          activeItems: curState.activeItems
                                                        });
                                            }
                                          }),
                                        onClose: (function (_event) {
                                            var curState = vnode.state.data;
                                            if (typeof curState !== "object") {
                                              return ;
                                            } else {
                                              return Mithril.setState(vnode, {
                                                          TAG: "Loaded",
                                                          checklist: curState.checklist,
                                                          levels: curState.levels,
                                                          newAnswers: curState.newAnswers,
                                                          guidanceUrl: curState.guidanceUrl,
                                                          filters: curState.filters,
                                                          showModal: false,
                                                          activeItems: curState.activeItems
                                                        });
                                            }
                                          })
                                      }, checklist.level_path, undefined)
                                ], R4SChecklist.filterItemsByType(checklist, Prelude.$$Array.first(Curry._2(Prelude.$$Array.keep, filters, (function (f) {
                                                  return f.query === "detType";
                                                })))).map(function (item) {
                                      var level = Prelude.$$Array.firstUnsafe(Curry._2(Prelude.$$Array.keep, levels, (function (l) {
                                                  return Caml_obj.equal(l.id, item.level_id);
                                                })));
                                      var str = item.detention_type;
                                      var subtitle = str !== undefined ? str + ", Deficiency code: " + item.code : "Deficiency code: " + item.code;
                                      return ReactDOM.jsxsKeyed("div", {
                                                  children: [
                                                    ReactDOM.jsx("div", {
                                                          children: ReactDOM.jsxs("div", {
                                                                children: [
                                                                  ReactDOM.jsxs("div", {
                                                                        children: [
                                                                          ReactDOM.jsx("div", {
                                                                                children: item.order + " " + item.description,
                                                                                className: "h5"
                                                                              }),
                                                                          ReactDOM.jsx("small", {
                                                                                children: subtitle,
                                                                                className: "tw-text-error"
                                                                              })
                                                                        ],
                                                                        className: "tw-w-10/12"
                                                                      }),
                                                                  ReactDOM.jsx("div", {
                                                                        children: React.jsxKeyed(TreeView.Completion.make, {
                                                                              level: level,
                                                                              useColor: true
                                                                            }, level.path + "-" + String(newAnswers.length), undefined),
                                                                        className: "tw-w-2/12"
                                                                      })
                                                                ],
                                                                className: "tw-flex tw-items-center",
                                                                onClick: (function (param) {
                                                                    toggleContent(vnode, level.path);
                                                                  })
                                                              }),
                                                          className: "tw-shadow-kaiko-card tw-p-2 tw-mx-auto tw-mb-2"
                                                        }),
                                                    Prelude.$$Array.includes(activeItems, level.path) ? React.jsx(R4SQuestionsView.make, {
                                                            vesselId: checklist.vessel_id,
                                                            levelId: level.id
                                                          }) : Mithril.empty
                                                  ],
                                                  className: "tw-p-2 sm:tw-w-10/12 tw-w-full tw-mx-auto"
                                                }, level.path + "-" + Prelude.default(JSON.stringify(R4SFilter.toUrlSearch(filters)), ""), undefined);
                                    })),
                            headerContent: {
                              NAME: "Title",
                              VAL: R4SChecklist.getShortName(checklist)
                            },
                            headerNav: "all"
                          }, checklist.id, undefined);
              }));
}

export {
  filterFromChecklist ,
  toggleContent ,
  createFilterHeader ,
  init ,
  update ,
  make ,
}
/* URLs Not a pure module */
