// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Feather from "../../common/views/Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as PageAppView from "../../common/views/PageAppView.js";
import * as ReviewUpload__UserProfiles from "./ReviewUpload__UserProfiles.js";
import * as ReviewUpload__CompanyFormsCard from "./ReviewUpload__CompanyFormsCard.js";
import * as ReviewUpload__FutureAttachment from "./ReviewUpload__FutureAttachment.js";
import * as ReviewUpload__NotificationsRead from "./ReviewUpload__NotificationsRead.js";
import * as ReviewUpload__CommentsStatusCard from "./ReviewUpload__CommentsStatusCard.js";
import * as ReviewUpload__InspectionPreviewCard from "./ReviewUpload__InspectionPreviewCard.js";
import * as ReviewUpload__LevelAnswersStatusCard from "./ReviewUpload__LevelAnswersStatusCard.js";

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var match = vnode.attrs;
                var onConfirmUpload = match.onConfirmUpload;
                var requests = match.requests;
                var tmp;
                if (requests.length !== 0) {
                  var tmp$1;
                  if (requests.length !== 0) {
                    var disabled = !ReviewUpload__UserProfiles.valid(requests) || !ReviewUpload__InspectionPreviewCard.valid(requests) || !ReviewUpload__LevelAnswersStatusCard.valid(requests) || !ReviewUpload__CommentsStatusCard.valid(requests) || !ReviewUpload__NotificationsRead.valid(requests) || !ReviewUpload__CompanyFormsCard.valid(requests) || !ReviewUpload__FutureAttachment.valid(requests);
                    tmp$1 = ReactDOM.jsx("div", {
                          children: ReactDOM.jsxs("button", {
                                children: [
                                  React.jsx(Feather.make, {
                                        icon: "upload"
                                      }),
                                  "Upload"
                                ],
                                className: "tw-w-full tw-flex tw-justify-center tw-items-center tw-shadow-kaiko-card tw-bg-success tw-text-white tw-border-0 tw-px-1",
                                disabled: disabled,
                                onClick: (function (param) {
                                    onConfirmUpload();
                                  })
                              }),
                          className: "tw-w-full tw-fixed tw-bottom-[55px] tw-px-2 tw-left-0 tw-right-0"
                        });
                  } else {
                    tmp$1 = Mithril.empty;
                  }
                  tmp = ReactDOM.jsxs("div", {
                        children: [
                          React.jsx(ReviewUpload__InspectionPreviewCard.make, {
                                requests: requests
                              }),
                          React.jsx(ReviewUpload__LevelAnswersStatusCard.make, {
                                requests: requests
                              }),
                          React.jsx(ReviewUpload__CommentsStatusCard.make, {
                                requests: requests
                              }),
                          React.jsx(ReviewUpload__NotificationsRead.make, {
                                requests: requests
                              }),
                          React.jsx(ReviewUpload__CompanyFormsCard.make, {
                                requests: requests
                              }),
                          React.jsx(ReviewUpload__UserProfiles.make, {
                                requests: requests
                              }),
                          React.jsx(ReviewUpload__FutureAttachment.make, {
                                requests: requests
                              }),
                          tmp$1
                        ],
                        className: "tw-p-2"
                      });
                } else {
                  tmp = ReactDOM.jsxs("div", {
                        children: [
                          ReactDOM.jsxs("div", {
                                children: [
                                  React.jsx(Feather.make, {
                                        icon: "upload",
                                        className: "p-centered",
                                        size: 45
                                      }),
                                  ReactDOM.jsx("div", {
                                        className: "divider blank xl"
                                      }),
                                  ReactDOM.jsx("h4", {
                                        children: "There is nothing to upload",
                                        className: "text-gray text-bold"
                                      })
                                ],
                                className: "text-center text-gray"
                              }),
                          ReactDOM.jsx("div", {})
                        ],
                        style: {
                          marginTop: "50%"
                        }
                      });
                }
                return React.jsx(PageAppView.make, {
                            children: ReactDOM.jsx("div", {
                                  children: tmp,
                                  className: "tw-px-2"
                                }),
                            headerContent: {
                              NAME: "Title",
                              VAL: "Upload preview"
                            },
                            headerNav: "all"
                          });
              }));
}

export {
  make ,
}
/* React Not a pure module */
