// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../URLs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Level from "../../tree/Level.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Feather from "../../common/views/Feather.js";
import * as LevelId from "../../tree/LevelId.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Question from "../../tree/Question.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as TileLink from "../../common/views/TileLink.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Sire2LevelStatus from "./Sire2LevelStatus.js";

function make(param) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), (function (vnode) {
                    var level = vnode.attrs.level;
                    Prelude.thenDo(Sire2LevelStatus.getSire2CompletionStatus(level.vessel_id, level.id), (function (completionStatus) {
                            Mithril.setState(vnode, {
                                  TAG: "Loaded",
                                  questionsCount: completionStatus.questionsCount,
                                  answersCount: completionStatus.answersCount
                                });
                          }));
                  })), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                }
                var answersCount = match.answersCount;
                var questionsCount = match.questionsCount;
                return ReactDOM.jsx("div", {
                            children: questionsCount !== answersCount ? ReactDOM.jsx("div", {
                                    children: String(answersCount) + "/" + String(questionsCount)
                                  }) : React.jsx(Feather.make, {
                                    icon: "check_circle",
                                    className: "text-success",
                                    size: 22
                                  }),
                            className: "text-center my-2"
                          });
              }));
}

async function load(vnode) {
  var match = vnode.state.data;
  if (typeof match === "object") {
    return ;
  }
  var levelId = vnode.attrs.levelId;
  var vesselId = vnode.attrs.vesselId;
  var riskFilters = Curry._2(Prelude.OptionExported.$$Option.map, Mithril$1.route.param("risks"), (function (p) {
          return Prelude.$$Array.keepSome(p.split(",").map(Level.riskStatusFromJs));
        }));
  var areaFilters = Curry._2(Prelude.OptionExported.$$Option.map, Mithril$1.route.param("areas"), (function (p) {
          return Prelude.$$Array.keepSome(p.split(",").map(function (x) {
                          return Question.QuestionArea.fromJs(x);
                        }));
        }));
  var answeredFilters = Curry._2(Prelude.OptionExported.$$Option.map, Mithril$1.route.param("answered"), (function (p) {
          return Prelude.$$Array.keepSome(p.split(",").map(function (x) {
                          return Question.Answered.fromJs(x);
                        }));
        }));
  var match$1 = await Sire2LevelStatus.getSire2LevelStatus(vesselId, levelId);
  return Mithril.setState(vnode, {
              TAG: "Loaded",
              subLevels: match$1.children,
              levelId: levelId,
              areaFilters: areaFilters,
              riskFilters: riskFilters,
              answeredFilters: answeredFilters
            });
}

function make$1(param) {
  return Mithril.view(Mithril.onupdate(Mithril.oninitAsync(Mithril.component("NotReady"), load), (function (vnode) {
                    var curRiskFilters = Curry._2(Prelude.OptionExported.$$Option.map, Mithril$1.route.param("risks"), (function (p) {
                            return Prelude.$$Array.keepSome(p.split(",").map(Level.riskStatusFromJs));
                          }));
                    var curAreaFilters = Curry._2(Prelude.OptionExported.$$Option.map, Mithril$1.route.param("areas"), (function (p) {
                            return Prelude.$$Array.keepSome(p.split(",").map(function (x) {
                                            return Question.QuestionArea.fromJs(x);
                                          }));
                          }));
                    var curAnsweredFilters = Curry._2(Prelude.OptionExported.$$Option.map, Mithril$1.route.param("answered"), (function (p) {
                            return Prelude.$$Array.keepSome(p.split(",").map(function (x) {
                                            return Question.Answered.fromJs(x);
                                          }));
                          }));
                    var curLevelId = LevelId.Route.param("levelId");
                    var match = vnode.state.data;
                    if (typeof match !== "object" || !(Caml_obj.notequal(curLevelId, match.levelId) || Caml_obj.notequal(curAreaFilters, match.areaFilters) || Caml_obj.notequal(curRiskFilters, match.riskFilters) || Caml_obj.notequal(curAnsweredFilters, match.answeredFilters))) {
                      return ;
                    } else {
                      vnode.state.data = "NotReady";
                      load(vnode);
                      return ;
                    }
                  })), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                }
                var vesselId = vnode.attrs.vesselId;
                return match.subLevels.map(function (subLevel) {
                            var areas = Mithril$1.route.param("areas");
                            var risks = Mithril$1.route.param("risks");
                            var answered = Mithril$1.route.param("answered");
                            var url = URLs.vesselLevelByKind(vesselId, subLevel.id, "sire_chapter", areas, risks, answered);
                            return React.jsxKeyed(TileLink.make, {
                                        title: subLevel.name,
                                        href: url,
                                        action: Caml_option.some(React.jsx(make, {
                                                  level: subLevel
                                                }))
                                      }, url, undefined);
                          });
              }));
}

export {
  make$1 as make,
}
/* URLs Not a pure module */
