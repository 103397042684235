// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Mithril from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Webapi__Dom__Element from "rescript-webapi/lib/es6/src/Webapi/Dom/Webapi__Dom__Element.js";

function setState(vnode, state) {
  vnode.state.data = state;
  Mithril.redraw();
}

function select(vnode, selector) {
  var dom = vnode.dom;
  if (dom === undefined) {
    return ;
  }
  var dom$1 = Caml_option.valFromOption(dom);
  return Belt_Option.flatMap(Belt_Option.getWithDefault(vnode.domSize, 1) === 1 ? Webapi__Dom__Element.ofNode(dom$1) : Caml_option.nullable_to_opt(dom$1.parentElement), (function (__x) {
                return Caml_option.nullable_to_opt(__x.querySelector(selector));
              }));
}

function asHtmlElement(element) {
  return Belt_Option.flatMap(element, Webapi__Dom__Element.asHtmlElement);
}

function asHtmlInputElement(element) {
  return Belt_Option.map(element, (function (prim) {
                return prim;
              }));
}

function value(element) {
  return Belt_Option.flatMap(Belt_Option.flatMap(element, Webapi__Dom__Element.asHtmlElement), (function (element) {
                return element.value;
              }));
}

function setValue(element, value) {
  Belt_Option.map(Belt_Option.map(element, (function (prim) {
              return prim;
            })), (function (element) {
          element.value = value;
        }));
}

function files(element) {
  return Belt_Option.flatMap(Belt_Option.flatMap(element, Webapi__Dom__Element.asHtmlElement), (function (element) {
                return element.files;
              }));
}

function focus(element) {
  Belt_Option.map(Belt_Option.flatMap(element, Webapi__Dom__Element.asHtmlElement), (function (prim) {
          prim.focus();
        }));
}

function click(element) {
  Belt_Option.map(Belt_Option.flatMap(element, Webapi__Dom__Element.asHtmlElement), (function (prim) {
          prim.click();
        }));
}

var $$Element = {
  select: select,
  asHtmlElement: asHtmlElement,
  asHtmlInputElement: asHtmlInputElement,
  value: value,
  setValue: setValue,
  files: files,
  focus: focus,
  click: click
};

function component(state) {
  return {
          data: state
        };
}

function oninit(component, oninit$1) {
  var newrecord = Caml_obj.obj_dup(component);
  newrecord.oninit = oninit$1;
  return newrecord;
}

function oninitAsync(component, oninit) {
  var newrecord = Caml_obj.obj_dup(component);
  newrecord.oninit = (function (vnode) {
      oninit(vnode);
    });
  return newrecord;
}

function oncreate(component, oncreate$1) {
  var newrecord = Caml_obj.obj_dup(component);
  newrecord.oncreate = oncreate$1;
  return newrecord;
}

function view(component, view$1) {
  var newrecord = Caml_obj.obj_dup(component);
  newrecord.view = view$1;
  return newrecord;
}

function onbeforeupdate(component, onbeforeupdate$1) {
  var newrecord = Caml_obj.obj_dup(component);
  newrecord.onbeforeupdate = onbeforeupdate$1;
  return newrecord;
}

function onupdate(component, onupdate$1) {
  var newrecord = Caml_obj.obj_dup(component);
  newrecord.onupdate = onupdate$1;
  return newrecord;
}

function onupdateAsync(component, onupdate) {
  var newrecord = Caml_obj.obj_dup(component);
  newrecord.onupdate = (function (vnode) {
      onupdate(vnode);
    });
  return newrecord;
}

function onbeforeremove(component, onbeforeremove$1) {
  var newrecord = Caml_obj.obj_dup(component);
  newrecord.onbeforeremove = onbeforeremove$1;
  return newrecord;
}

function onremove(component, onremove$1) {
  var newrecord = Caml_obj.obj_dup(component);
  newrecord.onremove = onremove$1;
  return newrecord;
}

var empty = null;

function $$class(classes, className) {
  if (className !== undefined) {
    classes.push([
          className,
          true
        ]);
  }
  return Belt_Array.keepMap(classes, (function (param) {
                  if (param[1]) {
                    return param[0];
                  }
                  
                })).join(" ");
}

function classList(classes, customClass) {
  if (customClass !== undefined) {
    classes.push(customClass);
  }
  return classes.join(" ");
}

function Mithril$Link(props) {
  return Mithril.route.Link;
}

var Link = {
  make: Mithril$Link
};

var Style;

var $$Request;

var Route;

export {
  Style ,
  $$Request ,
  Route ,
  setState ,
  $$Element ,
  component ,
  oninit ,
  oninitAsync ,
  oncreate ,
  view ,
  onbeforeupdate ,
  onupdate ,
  onupdateAsync ,
  onbeforeremove ,
  onremove ,
  empty ,
  $$class ,
  classList ,
  Link ,
}
/* empty Not a pure module */
