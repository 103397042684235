// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AppSchema from "../../AppSchema.js";
import * as R4SChecklistAPI from "../endpoints/R4SChecklistAPI.js";
import * as ServerSyncPipeline from "../common/ServerSyncPipeline.js";

function convertChecklist(state, checklist) {
  var r4sChecklist_vessel_id = checklist.vessel_id;
  var r4sChecklist_id = checklist.id;
  var r4sChecklist_level_id = checklist.level_id;
  var r4sChecklist_level_path = checklist.level_path;
  var r4sChecklist_port = checklist.port;
  var r4sChecklist_country = checklist.country;
  var r4sChecklist_eta = checklist.eta;
  var r4sChecklist_items = checklist.items;
  var r4sChecklist = {
    vessel_id: r4sChecklist_vessel_id,
    id: r4sChecklist_id,
    level_id: r4sChecklist_level_id,
    level_path: r4sChecklist_level_path,
    port: r4sChecklist_port,
    country: r4sChecklist_country,
    eta: r4sChecklist_eta,
    items: r4sChecklist_items
  };
  AppSchema.R4SChecklist.update(state, r4sChecklist);
  return state;
}

function convertChecklists(state, r4schecklists) {
  return Curry._3(Prelude.$$Array.fold, r4schecklists, state, convertChecklist);
}

function $$do(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  return Prelude.PromisedResult.map(Prelude.PromisedResult.map(R4SChecklistAPI.getAll(undefined, {
                      vessel__id__in: vessels.join(",")
                    }, undefined, user), (function (extra) {
                    return convertChecklists($$final, extra);
                  })), (function ($$final) {
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial,
                        final: $$final
                      };
              }));
}

var ReadServerState = {
  convertChecklist: convertChecklist,
  convertChecklists: convertChecklists,
  $$do: $$do
};

function $$do$1(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  return Prelude.PromisedResult.map(Query.Safe.read((newrecord.r4schecklists = {
                    TAG: "AnyOf",
                    _0: "vessel_id",
                    _1: vessels
                  }, newrecord)), (function (param) {
                Curry._2(Prelude.$$Array.forEach, param.r4schecklists, (function (extra) {
                        return AppSchema.R4SChecklist.update(initial, extra);
                      }));
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial,
                        final: $$final
                      };
              }));
}

var FillInitialStatus = {
  $$do: $$do$1
};

function $$do$2(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  var newrecord = Caml_obj.obj_dup(Query.makeWrite());
  newrecord.r4schecklists = AppSchema.R4SChecklist.getActions(initial, $$final);
  Query.ActionLogging.info("ServerSyncR4SChecklists" + ": db actions: ", newrecord);
  return Prelude.PromisedResult.map(Query.Safe.write(newrecord), (function (param) {
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial,
                        final: $$final
                      };
              }));
}

var UpdateDB = {
  $$do: $$do$2
};

var ApplyLocallyKnownState = ServerSyncPipeline.MakeNoOpPipelineStep({});

var views = [AppSchema.UserProfiles];

var Views = {
  views: views
};

var CollectFiles = ServerSyncPipeline.MakeFilesCollector(Views);

var CleanUpState = ServerSyncPipeline.MakeStandardCleanup(Views);

var include = ServerSyncPipeline.MakeStandardAPIPipeline({
      name: "ServerSyncR4SChecklists",
      ReadServerState: {
        $$do: $$do
      },
      FillInitialStatus: FillInitialStatus,
      ApplyLocallyKnownState: ApplyLocallyKnownState,
      UpdateDB: UpdateDB,
      CollectFiles: CollectFiles,
      CleanUpState: CleanUpState
    });

var $$do$3 = include.$$do;

export {
  ReadServerState ,
  FillInitialStatus ,
  UpdateDB ,
  $$do$3 as $$do,
}
/* include Not a pure module */
