// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as ButtonFilterModal from "../../common/components/ButtonFilterModal.js";
import * as FilterModalContainer from "../../common/components/FilterModalContainer.js";

function isOn(filters, query, value) {
  var filter = Prelude.$$Array.first(Curry._2(Prelude.$$Array.keep, filters, (function (filter) {
              return filter.query === query;
            })));
  if (filter !== undefined) {
    return Prelude.$$Array.includes(filter.value, value);
  } else {
    return false;
  }
}

function toUrlSearch(filters) {
  return Curry._1(Prelude.Dict.fromArray, filters.map(function (filter) {
                  return [
                          filter.query,
                          filter.value.join(",")
                        ];
                }));
}

function toggleFilter(filters, query, value) {
  var match = window.location;
  var newFilters = filters.map(function (filter) {
        if (filter.query !== query) {
          return filter;
        }
        var newValue = Prelude.$$Array.includes(filter.value, value) ? Curry._2(Prelude.$$Array.keep, filter.value, (function (v) {
                  return v !== value;
                })) : (
            filter.exclusive ? [value] : Curry._2(Prelude.$$Array.concat, filter.value, [value])
          );
        return {
                name: filter.name,
                query: filter.query,
                value: newValue,
                exclusive: filter.exclusive,
                availableValues: filter.availableValues
              };
      });
  Mithril$1.route.set(match.pathname, toUrlSearch(newFilters), {
        replace: true
      });
}

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var match = vnode.attrs;
                var onClose = match.onClose;
                var filters = match.filters;
                return React.jsx(FilterModalContainer.make, {
                            children: ReactDOM.jsx("div", {
                                  children: ReactDOM.jsx("div", {
                                        children: filters.map(function (filter) {
                                              return ReactDOM.jsxs("div", {
                                                          children: [
                                                            ReactDOM.jsx("div", {
                                                                  children: ReactDOM.jsx("label", {
                                                                        children: filter.name,
                                                                        className: "tw-font-medium"
                                                                      }),
                                                                  className: "tw-flex tw-flex-row"
                                                                }),
                                                            filter.availableValues.map(function (val) {
                                                                  var query = filter.query;
                                                                  var onTheme = ButtonFilterModal.Theme.on;
                                                                  var offTheme = ButtonFilterModal.Theme.off;
                                                                  return React.jsx(ButtonFilterModal.make, {
                                                                              text: val,
                                                                              onClick: (function (_event) {
                                                                                  toggleFilter(filters, query, val);
                                                                                }),
                                                                              theme: isOn(filters, query, val) ? onTheme : offTheme
                                                                            });
                                                                })
                                                          ]
                                                        });
                                            }),
                                        className: "tw-flex tw-flex-col tw-justify-start tw-gap-2"
                                      }),
                                  className: "tw-flex tw-flex-col tw-justify-start tw-gap-4"
                                }),
                            showModal: match.showModal,
                            textApplyButton: "Apply",
                            onClear: match.onClear,
                            onClose: onClose,
                            onApplyFilter: onClose
                          });
              }));
}

export {
  isOn ,
  toUrlSearch ,
  toggleFilter ,
  make ,
}
/* React Not a pure module */
