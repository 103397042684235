// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Level from "../../tree/Level.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as LevelId from "../../tree/LevelId.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Question from "../../tree/Question.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as QuizWidget from "../../tree/views/questions/QuizWidget.js";
import * as Sire2LevelStatus from "./Sire2LevelStatus.js";
import * as IntQuestionWidget from "../../tree/views/questions/IntQuestionWidget.js";
import * as DateQuestionWidget from "../../tree/views/questions/DateQuestionWidget.js";
import * as TextQuestionWidget from "../../tree/views/questions/TextQuestionWidget.js";
import * as PhotoQuestionWidget from "../../tree/views/questions/PhotoQuestionWidget.js";
import * as YesNoQuestionWidget from "../../tree/views/questions/YesNoQuestionWidget.js";
import * as RatingQuestionWidget from "../../tree/views/questions/RatingQuestionWidget.js";
import * as OKNotOKQuestionWidget from "../../tree/views/questions/OKNotOKQuestionWidget.js";
import * as SignatureQuestionWidget from "../../tree/views/questions/SignatureQuestionWidget.js";
import * as WeightInfoQuestionWidget from "../../tree/views/questions/WeightInfoQuestionWidget.js";
import * as MultipleDatesQuestionWidget from "../../tree/views/questions/MultipleDatesQuestionWidget.js";
import * as MultipleTextsQuestionWidget from "../../tree/views/questions/MultipleTextsQuestionWidget.js";
import * as MultipleIntegersQuestionWidget from "../../tree/views/questions/MultipleIntegersQuestionWidget.js";
import * as MultipleDatesTextsQuestionWidget from "../../tree/views/questions/MultipleDatesTextsQuestionWidget.js";
import * as MultipleDatesYesNoQuestionWidget from "../../tree/views/questions/MultipleDatesYesNoQuestionWidget.js";
import * as MultipleDatesIntegersQuestionWidget from "../../tree/views/questions/MultipleDatesIntegersQuestionWidget.js";

function load(vnode) {
  var levelId = vnode.attrs.levelId;
  var vesselId = vnode.attrs.vesselId;
  var risksFilter = Curry._2(Prelude.OptionExported.$$Option.map, Mithril$1.route.param("risks"), (function (p) {
          return Prelude.$$Array.keepSome(p.split(",").map(Level.riskStatusFromJs));
        }));
  var areasFilter = Curry._2(Prelude.OptionExported.$$Option.map, Mithril$1.route.param("areas"), (function (p) {
          return Prelude.$$Array.keepSome(p.split(",").map(function (x) {
                          return Question.QuestionArea.fromJs(x);
                        }));
        }));
  var answeredFilter = Curry._2(Prelude.OptionExported.$$Option.map, Mithril$1.route.param("answered"), (function (p) {
          return Prelude.$$Array.keepSome(p.split(",").map(function (x) {
                          return Question.Answered.fromJs(x);
                        }));
        }));
  Prelude.thenDo(Sire2LevelStatus.getSire2LevelStatus(vesselId, levelId), (function (levelStatus) {
          Mithril.setState(vnode, {
                TAG: "Loaded",
                vessel: levelStatus.vessel,
                level: levelStatus.level,
                questions: levelStatus.questions,
                children: levelStatus.children,
                allNewAnswers: levelStatus.allNewAnswers,
                risksFilter: risksFilter,
                areasFilter: areasFilter,
                answeredFilter: answeredFilter
              });
        }));
}

function make(param) {
  return Mithril.view(Mithril.onupdate(Mithril.oninit(Mithril.component("NotReady"), load), (function (vnode) {
                    var currentRisksFilter = Curry._2(Prelude.OptionExported.$$Option.map, Mithril$1.route.param("risks"), (function (p) {
                            return Prelude.$$Array.keepSome(p.split(",").map(Level.riskStatusFromJs));
                          }));
                    var currentAreasFilter = Curry._2(Prelude.OptionExported.$$Option.map, Mithril$1.route.param("areas"), (function (p) {
                            return Prelude.$$Array.keepSome(p.split(",").map(function (x) {
                                            return Question.QuestionArea.fromJs(x);
                                          }));
                          }));
                    var currentAnsweredFilter = Curry._2(Prelude.OptionExported.$$Option.map, Mithril$1.route.param("answered"), (function (p) {
                            return Prelude.$$Array.keepSome(p.split(",").map(function (x) {
                                            return Question.Answered.fromJs(x);
                                          }));
                          }));
                    var currentLevel = LevelId.Route.param("levelId");
                    var match = vnode.state.data;
                    if (typeof match !== "object" || !(Caml_obj.notequal(currentLevel, match.level.id) || Caml_obj.notequal(match.risksFilter, currentRisksFilter) || Caml_obj.notequal(match.areasFilter, currentAreasFilter) || Caml_obj.notequal(match.answeredFilter, currentAnsweredFilter))) {
                      return ;
                    } else {
                      return load(vnode);
                    }
                  })), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                }
                var questions = match.questions;
                var level = match.level;
                var vessel = match.vessel;
                if (Prelude.$$Array.isEmpty(questions)) {
                  return ReactDOM.jsx("div", {
                              children: ReactDOM.jsx("p", {
                                    children: "No results",
                                    className: "empty-title h5"
                                  }),
                              className: "empty"
                            });
                }
                var widgets = [ReactDOM.jsx("h5", {
                        children: level.name,
                        className: "p-2 chapter-title"
                      })];
                return Curry._2(Prelude.$$Array.concat, widgets, questions.map(function (param) {
                                var lastNewAnswer = param.lastNewAnswer;
                                var lastAnswer = param.lastAnswer;
                                var question = param.question;
                                var match = question.kind;
                                if (typeof match !== "object") {
                                  if (match === "PhotoQuestion") {
                                    return React.jsx(PhotoQuestionWidget.make, {
                                                question: question,
                                                level: level,
                                                vessel: vessel,
                                                lastNewAnswer: lastNewAnswer,
                                                lastAnswer: lastAnswer
                                              });
                                  } else if (match === "TextQuestion") {
                                    return React.jsx(TextQuestionWidget.make, {
                                                question: question,
                                                level: level,
                                                vessel: vessel,
                                                lastNewAnswer: lastNewAnswer,
                                                lastAnswer: lastAnswer
                                              });
                                  } else if (match === "DateQuestion") {
                                    return React.jsx(DateQuestionWidget.make, {
                                                question: question,
                                                level: level,
                                                vessel: vessel,
                                                lastNewAnswer: lastNewAnswer,
                                                lastAnswer: lastAnswer
                                              });
                                  } else if (match === "SignatureQuestion") {
                                    return React.jsx(SignatureQuestionWidget.make, {
                                                question: question,
                                                level: level,
                                                vessel: vessel,
                                                lastNewAnswer: lastNewAnswer,
                                                lastAnswer: lastAnswer
                                              });
                                  } else {
                                    return React.jsx(IntQuestionWidget.make, {
                                                question: question,
                                                level: level,
                                                vessel: vessel,
                                                lastNewAnswer: lastNewAnswer,
                                                lastAnswer: lastAnswer
                                              });
                                  }
                                }
                                var variant = match.NAME;
                                if (variant === "OKNotOKQuestion") {
                                  return React.jsx(OKNotOKQuestionWidget.make, {
                                              question: question,
                                              level: level,
                                              vessel: vessel,
                                              lastNewAnswer: lastNewAnswer,
                                              lastAnswer: lastAnswer
                                            });
                                } else if (variant === "MultipleTextsQuestion") {
                                  return React.jsx(MultipleTextsQuestionWidget.make, {
                                              question: question,
                                              level: level,
                                              vessel: vessel,
                                              lastNewAnswer: lastNewAnswer,
                                              lastAnswer: lastAnswer
                                            });
                                } else if (variant === "RatingQuestion") {
                                  return React.jsx(RatingQuestionWidget.make, {
                                              question: question,
                                              level: level,
                                              vessel: vessel,
                                              lastNewAnswer: lastNewAnswer,
                                              lastAnswer: lastAnswer
                                            });
                                } else if (variant === "WeightInfoQuestion") {
                                  return React.jsx(WeightInfoQuestionWidget.make, {
                                              question: question,
                                              level: level,
                                              vessel: vessel,
                                              lastNewAnswer: lastNewAnswer,
                                              lastAnswer: lastAnswer
                                            });
                                } else if (variant === "MultipleDatesTextsQuestion") {
                                  return React.jsx(MultipleDatesTextsQuestionWidget.make, {
                                              question: question,
                                              level: level,
                                              vessel: vessel,
                                              lastNewAnswer: lastNewAnswer,
                                              lastAnswer: lastAnswer
                                            });
                                } else if (variant === "MultipleDatesYesNoQuestion") {
                                  return React.jsx(MultipleDatesYesNoQuestionWidget.make, {
                                              question: question,
                                              level: level,
                                              vessel: vessel,
                                              lastNewAnswer: lastNewAnswer,
                                              lastAnswer: lastAnswer
                                            });
                                } else if (variant === "MultipleIntegersQuestion") {
                                  return React.jsx(MultipleIntegersQuestionWidget.make, {
                                              question: question,
                                              level: level,
                                              vessel: vessel,
                                              lastNewAnswer: lastNewAnswer,
                                              lastAnswer: lastAnswer
                                            });
                                } else if (variant === "YesNoQuestion") {
                                  return React.jsx(YesNoQuestionWidget.make, {
                                              question: question,
                                              level: level,
                                              vessel: vessel,
                                              lastNewAnswer: lastNewAnswer,
                                              lastAnswer: lastAnswer
                                            });
                                } else if (variant === "MultipleDatesQuestion") {
                                  return React.jsx(MultipleDatesQuestionWidget.make, {
                                              question: question,
                                              level: level,
                                              vessel: vessel,
                                              lastNewAnswer: lastNewAnswer,
                                              lastAnswer: lastAnswer
                                            });
                                } else if (variant === "Quiz") {
                                  return React.jsx(QuizWidget.make, {
                                              question: question,
                                              level: level,
                                              vessel: vessel,
                                              lastNewAnswer: lastNewAnswer,
                                              lastAnswer: lastAnswer
                                            });
                                } else {
                                  return React.jsx(MultipleDatesIntegersQuestionWidget.make, {
                                              question: question,
                                              level: level,
                                              vessel: vessel,
                                              lastNewAnswer: lastNewAnswer,
                                              lastAnswer: lastAnswer
                                            });
                                }
                              }));
              }));
}

export {
  load ,
  make ,
}
/* Level Not a pure module */
