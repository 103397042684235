// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../URLs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Level from "../../tree/Level.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Feather from "../../common/views/Feather.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as LevelId from "../../tree/LevelId.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Question from "../../tree/Question.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as VesselId from "../../vessel/VesselId.js";
import * as ListLayout from "../../common/views/ListLayout.js";
import * as Sire2Filter from "./Sire2Filter.js";
import * as ButtonSlimIcon from "../../common/components/ButtonSlimIcon.js";
import * as ButtonSlimLink from "../../common/components/ButtonSlimLink.js";
import * as KeepScrollLink from "../../common/views/KeepScrollLink.js";
import * as Sire2LevelStatus from "./Sire2LevelStatus.js";
import * as ButtonFilterToggle from "../../common/components/ButtonFilterToggle.js";
import * as Sire2LevelChildren from "./Sire2LevelChildren.js";
import * as Sire2LevelQuestionView from "./Sire2LevelQuestionView.js";

function dataFromUrl() {
  var vesselId = VesselId.Route.param("vesselId");
  var levelId = LevelId.Route.param("levelId");
  var areaFilters = Curry._2(Prelude.OptionExported.$$Option.map, Mithril$1.route.param("areas"), (function (p) {
          return Prelude.$$Array.keepSome(p.split(",").map(function (x) {
                          return Question.QuestionArea.fromJs(x);
                        }));
        }));
  var riskFilters = Curry._2(Prelude.OptionExported.$$Option.map, Mithril$1.route.param("risks"), (function (p) {
          return Prelude.$$Array.keepSome(p.split(",").map(Level.riskStatusFromJs));
        }));
  var answeredFilters = Curry._2(Prelude.OptionExported.$$Option.map, Mithril$1.route.param("answered"), (function (p) {
          return Prelude.$$Array.keepSome(p.split(",").map(function (x) {
                          return Question.Answered.fromJs(x);
                        }));
        }));
  return {
          vesselId: vesselId,
          levelId: levelId,
          areaFilters: areaFilters,
          riskFilters: riskFilters,
          answeredFilters: answeredFilters
        };
}

function stateFromClassFilters() {
  var match = dataFromUrl();
  var answeredFilters = match.answeredFilters;
  var riskFilters = match.riskFilters;
  var areaFilters = match.areaFilters;
  var filterState = Sire2Filter.Filter.defaultFalse;
  var filterState$1 = areaFilters !== undefined ? areaFilters.reduce((function (curFilterState, filter) {
            if (filter === "Hardware") {
              return {
                      hardware: true,
                      human: curFilterState.human,
                      process: curFilterState.process,
                      high: curFilterState.high,
                      medium: curFilterState.medium,
                      low: curFilterState.low,
                      answered: curFilterState.answered,
                      unanswered: curFilterState.unanswered
                    };
            } else if (filter === "Process") {
              return {
                      hardware: curFilterState.hardware,
                      human: curFilterState.human,
                      process: true,
                      high: curFilterState.high,
                      medium: curFilterState.medium,
                      low: curFilterState.low,
                      answered: curFilterState.answered,
                      unanswered: curFilterState.unanswered
                    };
            } else {
              return {
                      hardware: curFilterState.hardware,
                      human: true,
                      process: curFilterState.process,
                      high: curFilterState.high,
                      medium: curFilterState.medium,
                      low: curFilterState.low,
                      answered: curFilterState.answered,
                      unanswered: curFilterState.unanswered
                    };
            }
          }), filterState) : filterState;
  var filterState$2 = riskFilters !== undefined ? riskFilters.reduce((function (curFilterState, filter) {
            if (filter === "medium") {
              return {
                      hardware: curFilterState.hardware,
                      human: curFilterState.human,
                      process: curFilterState.process,
                      high: curFilterState.high,
                      medium: true,
                      low: curFilterState.low,
                      answered: curFilterState.answered,
                      unanswered: curFilterState.unanswered
                    };
            } else if (filter === "low") {
              return {
                      hardware: curFilterState.hardware,
                      human: curFilterState.human,
                      process: curFilterState.process,
                      high: curFilterState.high,
                      medium: curFilterState.medium,
                      low: true,
                      answered: curFilterState.answered,
                      unanswered: curFilterState.unanswered
                    };
            } else {
              return {
                      hardware: curFilterState.hardware,
                      human: curFilterState.human,
                      process: curFilterState.process,
                      high: true,
                      medium: curFilterState.medium,
                      low: curFilterState.low,
                      answered: curFilterState.answered,
                      unanswered: curFilterState.unanswered
                    };
            }
          }), filterState$1) : filterState$1;
  if (answeredFilters !== undefined) {
    return answeredFilters.reduce((function (curFilterState, filter) {
                  if (filter === "Answered") {
                    return {
                            hardware: curFilterState.hardware,
                            human: curFilterState.human,
                            process: curFilterState.process,
                            high: curFilterState.high,
                            medium: curFilterState.medium,
                            low: curFilterState.low,
                            answered: true,
                            unanswered: curFilterState.unanswered
                          };
                  } else {
                    return {
                            hardware: curFilterState.hardware,
                            human: curFilterState.human,
                            process: curFilterState.process,
                            high: curFilterState.high,
                            medium: curFilterState.medium,
                            low: curFilterState.low,
                            answered: curFilterState.answered,
                            unanswered: true
                          };
                  }
                }), filterState$2);
  } else {
    return filterState$2;
  }
}

async function load(vnode) {
  var levelId = LevelId.Route.param("levelId");
  var vesselId = VesselId.Route.param("vesselId");
  var match = await Sire2LevelStatus.levelHasChildren(vesselId, levelId);
  var level = match[0];
  var isLeaf = !match[1];
  var title = level.name;
  var guidanceUrl = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, level.guidance_document_id, (function (docId) {
              return URLs.markdownDocument(docId);
            })), undefined);
  var filterState = stateFromClassFilters();
  return Mithril.setState(vnode, {
              TAG: "Loaded",
              vesselId: vesselId,
              levelId: levelId,
              title: title,
              isLeaf: isLeaf,
              guidanceUrl: guidanceUrl,
              filterState: filterState,
              showModal: false
            });
}

function saveToUrl(areaFilterStr, riskFilterStr, statusFilterStr) {
  var match = window.location;
  var searchParameters = new URLSearchParams(match.search);
  searchParameters.set("areas", areaFilterStr);
  searchParameters.set("risks", riskFilterStr);
  searchParameters.set("answered", statusFilterStr);
  Mithril$1.route.set(match.pathname, Js_dict.fromArray(Array.from(searchParameters.entries())), {
        replace: true
      });
}

function filterUpdate(vnode, filterType, newValue) {
  var curState = vnode.state.data;
  if (typeof curState !== "object") {
    return ;
  }
  var filterState = curState.filterState;
  var newFilterState = filterType === "answered" ? ({
        hardware: filterState.hardware,
        human: filterState.human,
        process: filterState.process,
        high: filterState.high,
        medium: filterState.medium,
        low: filterState.low,
        answered: newValue,
        unanswered: filterState.unanswered
      }) : (
      filterType === "unanswered" ? ({
            hardware: filterState.hardware,
            human: filterState.human,
            process: filterState.process,
            high: filterState.high,
            medium: filterState.medium,
            low: filterState.low,
            answered: filterState.answered,
            unanswered: newValue
          }) : (
          filterType === "process" ? ({
                hardware: filterState.hardware,
                human: filterState.human,
                process: newValue,
                high: filterState.high,
                medium: filterState.medium,
                low: filterState.low,
                answered: filterState.answered,
                unanswered: filterState.unanswered
              }) : (
              filterType === "hardware" ? ({
                    hardware: newValue,
                    human: filterState.human,
                    process: filterState.process,
                    high: filterState.high,
                    medium: filterState.medium,
                    low: filterState.low,
                    answered: filterState.answered,
                    unanswered: filterState.unanswered
                  }) : (
                  filterType === "medium" ? ({
                        hardware: filterState.hardware,
                        human: filterState.human,
                        process: filterState.process,
                        high: filterState.high,
                        medium: newValue,
                        low: filterState.low,
                        answered: filterState.answered,
                        unanswered: filterState.unanswered
                      }) : (
                      filterType === "low" ? ({
                            hardware: filterState.hardware,
                            human: filterState.human,
                            process: filterState.process,
                            high: filterState.high,
                            medium: filterState.medium,
                            low: newValue,
                            answered: filterState.answered,
                            unanswered: filterState.unanswered
                          }) : (
                          filterType === "human" ? ({
                                hardware: filterState.hardware,
                                human: newValue,
                                process: filterState.process,
                                high: filterState.high,
                                medium: filterState.medium,
                                low: filterState.low,
                                answered: filterState.answered,
                                unanswered: filterState.unanswered
                              }) : ({
                                hardware: filterState.hardware,
                                human: filterState.human,
                                process: filterState.process,
                                high: newValue,
                                medium: filterState.medium,
                                low: filterState.low,
                                answered: filterState.answered,
                                unanswered: filterState.unanswered
                              })
                        )
                    )
                )
            )
        )
    );
  var areaFilters = [];
  if (newFilterState.hardware) {
    areaFilters.push(Question.QuestionArea.toJs("Hardware"));
  }
  if (newFilterState.human) {
    areaFilters.push(Question.QuestionArea.toJs("Human"));
  }
  if (newFilterState.process) {
    areaFilters.push(Question.QuestionArea.toJs("Process"));
  }
  var riskFilters = [];
  if (newFilterState.high) {
    riskFilters.push(Level.riskStatusToJs("high"));
  }
  if (newFilterState.medium) {
    riskFilters.push(Level.riskStatusToJs("medium"));
  }
  if (newFilterState.low) {
    riskFilters.push(Level.riskStatusToJs("low"));
  }
  var statusFilters = [];
  if (newFilterState.answered) {
    statusFilters.push(Question.Answered.toJs("Answered"));
  }
  if (newFilterState.unanswered) {
    statusFilters.push(Question.Answered.toJs("Unanswered"));
  }
  var areaFilterStr = areaFilters.join(",");
  var riskFilterStr = riskFilters.join(",");
  var statusFilterStr = statusFilters.join(",");
  saveToUrl(areaFilterStr, riskFilterStr, statusFilterStr);
  Mithril.setState(vnode, {
        TAG: "Loaded",
        vesselId: curState.vesselId,
        levelId: curState.levelId,
        title: curState.title,
        isLeaf: curState.isLeaf,
        guidanceUrl: curState.guidanceUrl,
        filterState: newFilterState,
        showModal: curState.showModal
      });
}

function createFilterHeader(vnode) {
  var match = vnode.state.data;
  if (typeof match !== "object") {
    return Mithril.empty;
  }
  var filterState = match.filterState;
  var guidanceUrl = match.guidanceUrl;
  var hasFilters = Sire2Filter.Filter.anyFilter(filterState);
  var hasGuidance = Curry._1(Prelude.OptionExported.$$Option.isSome, guidanceUrl);
  var filterButton = React.jsx(ButtonSlimIcon.make, {
        icon: "filter",
        onClick: (function (_event) {
            var curState = vnode.state.data;
            if (typeof curState !== "object") {
              return ;
            } else {
              return Mithril.setState(vnode, {
                          TAG: "Loaded",
                          vesselId: curState.vesselId,
                          levelId: curState.levelId,
                          title: curState.title,
                          isLeaf: curState.isLeaf,
                          guidanceUrl: curState.guidanceUrl,
                          filterState: curState.filterState,
                          showModal: true
                        });
            }
          }),
        className: "tw-pl-2"
      });
  var activeFilterButtons = Sire2Filter.Filter.activeFilters(filterState).map(function (filter) {
        return React.jsx(ButtonFilterToggle.make, {
                    text: Sire2Filter.Filter.toString(filter),
                    onClick: (function (_event) {
                        filterUpdate(vnode, filter, false);
                      })
                  });
      });
  var guidanceLink = guidanceUrl !== undefined ? React.jsxs(ButtonSlimLink.make, {
          children: [
            React.jsx(Feather.make, {
                  icon: "book_open"
                }),
            ReactDOM.jsx("span", {
                  children: "Guidance"
                })
          ],
          href: guidanceUrl
        }) : Mithril.empty;
  if (hasFilters && hasGuidance) {
    return ReactDOM.jsxs("div", {
                children: [
                  ReactDOM.jsxs("div", {
                        children: [
                          filterButton,
                          guidanceLink
                        ],
                        className: "tw-flex tw-flex-row tw-items-center tw-justify-between"
                      }),
                  ReactDOM.jsx("div", {
                        children: activeFilterButtons,
                        className: Mithril.classList([
                              "tw-flex tw-flex-row tw-items-center tw-justify-start tw-flex-wrap",
                              "tw-gap-1 tw-px-1 tw-pt-2"
                            ], undefined)
                      })
                ],
                className: "tw-flex tw-flex-col"
              });
  } else if (hasGuidance) {
    return ReactDOM.jsxs("div", {
                children: [
                  filterButton,
                  guidanceLink
                ],
                className: "tw-flex tw-flex-row tw-items-center tw-justify-between"
              });
  } else if (hasFilters) {
    return ReactDOM.jsxs("div", {
                children: [
                  filterButton,
                  activeFilterButtons
                ],
                className: Mithril.classList([
                      "tw-flex tw-flex-row tw-items-center tw-justify-start tw-flex-wrap",
                      "tw-gap-1"
                    ], undefined)
              });
  } else {
    return ReactDOM.jsx("div", {
                children: filterButton,
                className: "tw-flex tw-flex-row tw-items-center tw-justify-start"
              });
  }
}

function make(param) {
  return Mithril.view(Mithril.onupdate(Mithril.oninitAsync(Mithril.component("NotReady"), load), (function (vnode) {
                    var urlData = dataFromUrl();
                    var curLevelId = urlData.levelId;
                    var match = vnode.state.data;
                    if (typeof match !== "object" || !Caml_obj.notequal(curLevelId, match.levelId)) {
                      return ;
                    } else {
                      load(vnode);
                      return KeepScrollLink.restoreScroll(undefined);
                    }
                  })), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                }
                var levelId = match.levelId;
                var vesselId = match.vesselId;
                return React.jsxs(ListLayout.make, {
                            children: [
                              React.jsx(Sire2Filter.make, {
                                    showModal: match.showModal,
                                    filterState: match.filterState,
                                    filterUpdate: (function (filter, newValue) {
                                        filterUpdate(vnode, filter, newValue);
                                      }),
                                    onClear: (function (_event) {
                                        var curState = vnode.state.data;
                                        if (typeof curState !== "object") {
                                          return ;
                                        } else {
                                          return Mithril.setState(vnode, {
                                                      TAG: "Loaded",
                                                      vesselId: curState.vesselId,
                                                      levelId: curState.levelId,
                                                      title: curState.title,
                                                      isLeaf: curState.isLeaf,
                                                      guidanceUrl: curState.guidanceUrl,
                                                      filterState: Sire2Filter.Filter.defaultFalse,
                                                      showModal: curState.showModal
                                                    });
                                        }
                                      }),
                                    onClose: (function (_event) {
                                        var curState = vnode.state.data;
                                        if (typeof curState !== "object") {
                                          return ;
                                        } else {
                                          return Mithril.setState(vnode, {
                                                      TAG: "Loaded",
                                                      vesselId: curState.vesselId,
                                                      levelId: curState.levelId,
                                                      title: curState.title,
                                                      isLeaf: curState.isLeaf,
                                                      guidanceUrl: curState.guidanceUrl,
                                                      filterState: curState.filterState,
                                                      showModal: false
                                                    });
                                        }
                                      })
                                  }),
                              ReactDOM.jsxs("div", {
                                    children: [
                                      createFilterHeader(vnode),
                                      ReactDOM.jsx("div", {
                                            children: match.isLeaf ? React.jsxKeyed(Sire2LevelQuestionView.make, {
                                                    vesselId: vesselId,
                                                    levelId: levelId
                                                  }, levelId, undefined) : React.jsx(Sire2LevelChildren.make, {
                                                    vesselId: vesselId,
                                                    levelId: levelId
                                                  }),
                                            className: "t-level-elements"
                                          })
                                    ],
                                    className: "tw-flex tw-flex-col tw-gap-1"
                                  })
                            ],
                            title: match.title,
                            showBackButton: true
                          });
              }));
}

export {
  make ,
}
/* URLs Not a pure module */
